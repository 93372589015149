.common_container {
  padding-inline: 6rem;
}

.head_container {
  background-color: #fff;
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.react__icon {
  font-size: 3.375rem;
  font-weight: 900;
  color: #fff
}

.header__div {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
  position: sticky;
}

.heading_detail {
  display: flex;
  justify-content: space-between;

}

.download_button {
  height: 30px;
  width: 100px;
  display: flex;
  gap: 1rem;
}

.heading:hover {
  color: #0E4A96;
  cursor: pointer;

}

.heading_active {
  color: #0E4A96;
  /* Change the text color when the text is clicked */
  font-size: small;
  text-decoration: none;
  color: #262626;
  font-weight: 600;
}

.heading {
  font-size: small;
  text-decoration: none;
  color: #262626;
  font-weight: 600;
  display: flex;
  flex-direction: column;
}

.background_img {
  height: 90vh;
  background-image: url('../assets//first_crousel_Img.png');
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.background_img_container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.background_img::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9;
  opacity: 0.6;
}

.swap_text {
  z-index: 100;
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  top: 45%;
  width: 100%
}

.text_para {
  font-size: 1rem;

}

.try_button {
  color: white;
  height: 3rem;
  width: 10rem;
  margin-top: 35px;
  border: none;
  background-image: linear-gradient(to right, #009CDE, #0E4A96);
}

.try_button:hover {
  background-image: linear-gradient(to left, #009CDE, #0E4A96);
}

.heaing_tag {
  font-weight: 1000;
  font-size: 2.375rem;

}

.aboutus_background {
  background-position: center;
  background-image: url('../assets//aboutBack.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.leftside_about {
  text-align: justify;
  margin-top: 20px;
}

.car_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  font-style: italic;
  font-weight: 700;
  font-size: 2rem;

}

.features_section {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.paragraph {
  font-size: 1rem;
  /* padding-inline-end: 2rem; */
}

.section_paragraph {
  /* width: 80%; */
  /* min-height: 90vh; */
  display: grid;
  place-items: center;
}

.aboutPadding {
  padding: 4rem 0px;
}


.twoplayer_img {
  width: 100%;
  margin: auto;
  margin-top: 2.5rem;
  /* margin-left: 90px; */
}

.slidebutton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  /* margin-left: 87px; */
  top: 50%;

}

.showMenu_btn {
  display: none;
}

.features_section {
  text-align: center;
}

.row_section {
  display: flex;
  text-align: justify;
  justify-content: space-between;
  margin: auto;
  margin-left: 10px;


}

.awesome_MainDiv {
  /* width: 90%; */
}

.features_image {
  /* height: 180px; */
  width: 100%;
}

.col__container {
  display: flex;
  text-align: center;
  margin: auto;
  margin-left: 35px;
}

.service {
  border-radius: 5px;
  margin-right: 20px;
  height: 100%;
}

.contact_button {
  background-color: #0E4A96;
  height: 2.5rem;
  width: 10rem;
  margin-top: 35px;
  text-align: center;
}

.mobile_image {
  width: 100%;
}

.download_section {
  background-image: url('../assets/aboutBack.png');
  background-position: center;
  background-size: cover;
  margin-top: 50px;
}

.mobile_background {
  height: 100%;
  display: flex;
  justify-content: space-around;
}

.dwnldContent_parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.playestore_button {
  /* height: 80px; */
  /* width: 140px; */
  display: flex;
  gap: 1rem;
  margin-top: 20px;
}

.playestore_button img {
  height: 50px;
}

.form_style {
  background-color: #F2F8FF;
  width: 80%;
  border: none;
  padding: 10px;
}

.messageform_style {
  background-color: #F2F8FF;
  width: 80%;
  border: none;
  padding-bottom: 40px;

}

.send_button {
  background-color: #0E4A96;
  border-radius: 50px;
  color: #fff;
  border: none;
  height: 38px;
  min-width: 9rem;
  font-weight: 500;
}

.send_button:hover {
  color: #000 !important;
}

.footer:hover {
  color: #0E4A96;
}

.footer_parent {
  background-image: url('../assets/footerback.png');
  background-position: center;
  background-size: cover;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
}

.footer_detail {
  display: flex;
  justify-content: space-around;
  color: #000 !important;
  margin-inline: auto;
  margin-bottom: 33px;
  gap: 3rem;
  align-items: center;
}

.footer {
  font-size: small;
  text-decoration: none;
  color: #262626;
  font-weight: 700;
  font-size: 1rem;
}

.bottom {
  font-size: 1rem;
  padding: 8px;
  background-color: #0E4A96;
  /* margin-bottom: 50px; */
  min-height: 40px;
  text-align: center;
  color: #fff;
}

.heder_space {
  display: flex;
  justify-content: space-between;
  text-align: center;
}


@media screen and (min-width: 1440px) and (max-width: 2560px) {
  .head_container {
    height: 100px;
  }


  .swap_text {
    top: 45%;

  }

  .logo {
    height: 42px;
    width: 126px;
  }

  .heading {
    font-size: 1.2rem;
    font-weight: 700;
  }


  .download_button {
    height: 42px;
    width: 140px;

  }

  .heaing_tag {
    font-weight: 1000;
    font-size: 4rem;
  }

  .text_para {
    font-size: 2rem;
  }

  .try_button {
    background-color: #0E4A96;
    height: 3.5rem;
    width: 15rem;
    border-radius: 40px;
    font-size: 1.5rem;
  }

  .title {
    font-style: italic;
    font-weight: 700;
    font-size: 3rem;
  }

  .paragraph {
    font-size: 1rem;
  }

  .react__icon {
    font-size: 4rem;
    font-weight: 900;
    color: #fff
  }

  .slidebutton {
    position: absolute;
    width: 93%;
    /* margin-left: 83px; */

  }

}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .try_button {
    height: 3rem;
    width: 11rem;
  }

  .slidebutton {
    width: 86%;
    /* margin-left: 89px; */
  }

  .swap_text {
    top: 40%;

  }

  .features_section {
    text-align: center;
    margin-top: 15px;
  }

  .twoplayer_img {
    /* width: 88%; */
  }


  .footer_parent {
    height: 250px;
  }

  .mobile_image {
    width: 100%;
  }

}

@media screen and (min-width: 993px) and (max-width: 1024px) {
  .mobile_image {
    width: 100%;
  }

  .swap_text {
    top: 40%;

  }

  .form_style {
    width: 80%;
  }

  .messageform_style {
    width: 80%;
  }

  .footer_detail {
    gap: 1rem;
  }

  .slidebutton {
    width: 83%;
    /* margin-left: 85px; */
  }
}

@media screen and (max-width:992px) {
  .mobile_image {
    margin-top: 30px;
  }

  .heading_detail {
    display: none !important;
  }

  .messageform_style {
    width: 100%;
  }

  .aboutPadding {
    padding: 0rem 0px 1rem 0;
  }

  .title {
    text-align: center;
  }

  .common_container {

    padding-inline: 2rem;
  }

  .download_button {
    display: none;
  }

  .showMenu_btn {
    display: flex;
    align-items: center;
  }

  .leftside_about {
    margin-top: 0px;
    margin-bottom: 1rem;
  }

  .playestore_button {
    flex-direction: column;
    /* margin: auto; */
  }

  .footer_detail {
    flex-direction: column;
    gap: 0rem;
  }

  .heading {
    font-size: medium;
  }

  .form_style {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .rightside_player {
    margin-top: 1rem;
  }

  #form {
    min-width: 74%;
  }

  .aboutPadding {
    padding: 0rem 0rem;
  }

  .dwnldContent_parent {
    padding-inline: 2rem;
    padding-top: 30px;
  }

  .footer_detail {
    flex-direction: column;
    gap: 0rem;
  }

  .playestore_button {
    flex-direction: column;
    width: 120px;
    margin: auto;
  }

  .contact_form {
    padding-inline: 2rem;

  }

  .contact_girl {
    display: flex;
    justify-content: center;
  }

  .head_container {
    justify-content: space-between;
    padding-inline: 0rem;
    padding-top: 0.5rem;
  }

  .common_container {
    padding-inline: 2rem;

  }

  .download_butt {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .download_butt>img {
    height: 30%;
    width: 40%;
  }

  .heading {
    font-size: large;
    width: 100%;
    display: flex;
  }


  .leftside_about {
    text-align: justify;
    margin-top: 50px;
    padding-inline: 2rem;
  }

  .react__icon {
    font-size: 3rem;
    font-weight: 900;
    color: #fff
  }

  .footer_parent {
    height: 200px;
  }

  .swap_text {
    top: 34%;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .slidebutton {
    width: 92%;
    /* margin-left: 31px; */
  }

  .twoplayer_img {
    width: 100%;
  }

  .swap_text {
    top: 34%;

  }

}


@media screen and (min-width: 321px) and (max-width: 425px) {

  .heaing_tag {
    font-weight: 700;
    font-size: 1.375rem;
  }


  .swap_text {
    top: 34%;

  }

  .slidebutton {
    position: absolute;
    width: 95%;
  }

  .footer_parent {
    height: 200px;
  }

  .footer_detail {
    gap: 0rem;
  }

  .react__icon {
    font-size: 2rem;
    font-weight: 900;
    color: #fff
  }

  .slidebutton {
    width: 98%;
    /* margin-left: 2px; */
  }

  .playestore_button img {
    height: 68px;
    width: 58%;
    margin: auto;
  }
}
.solid_link:hover{
  text-decoration: underline  !important;
}